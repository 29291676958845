export const PRODUCTION = 'production'
export const DEVELOPMENT = 'development'
export const CHUNK_LOAD_FAILURE_STORAGE_KEY = 'CHUNK_LOAD_FAILURE_STORAGE_KEY'
export const CHUNK_LOAD_FAILURE_TIMEOUT = 5000
export enum EContour {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  STAGE = 'STAGE',
  PRODUCTION = 'PRODUCTION',
}

export const SPACER = 8
