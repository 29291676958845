import { TEntries } from '@common/types/services'
import { ROOT_NODE_ID } from '@constants/client'
import { EContour } from '@constants/system'

export const getRootNode = () => document.getElementById(ROOT_NODE_ID)

export const getContour = () => {
  switch (true) {
    case location.origin.includes('localhost'): {
      return EContour.LOCAL
    }

    case location.origin.includes('dev.k-stage'): {
      return EContour.DEV
    }

    case location.origin.includes('k-stage'): {
      return EContour.STAGE
    }

    default: {
      return EContour.PRODUCTION
    }
  }
}

export const getObjectEntries = <T>(obj: T): TEntries<T> => Object.entries(obj) as TEntries<T>
