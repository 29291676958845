import axios from 'axios'

import { IEnv } from '@common/types/env'
import { getContour } from '@common/utils/client'
import { EContour } from '@constants/system'

const STORAGE_KEY = 'localEnvStorage'
const contour = getContour()

// todo Удалить после переезда на apigw
export class Environments {
  public readonly rawEnv: IEnv
  public readonly localEnv: Partial<IEnv>

  constructor(env: IEnv) {
    this.rawEnv = env

    const localEnv = localStorage.getItem(STORAGE_KEY)

    if (localEnv && contour !== EContour.PRODUCTION) {
      const envBody = JSON.parse(localEnv) as Partial<IEnv>

      if (Object.keys(envBody).length > 0) {
        console.log(
          `%cДруг, ты используешь локальные переменные окружения, будь бдителен`,
          'background-color: black; color: white'
        )
      }

      this.localEnv = envBody
    } else {
      this.localEnv = {}
    }
  }

  /**
   * Метод получения текущего значения переменных окружения
   * @returns IEnv */
  get env() {
    return {
      ...this.rawEnv,
      ...this.localEnv,
    }
  }

  /** Метод очистки переменных окружения */
  public clearLocalEnv() {
    localStorage.removeItem(STORAGE_KEY)
    location.reload()
  }

  /**
   * Метод установки переменной окружения по ключу
   * @param key
   * @param value
   */
  public setLocalEnvByKey(key: keyof IEnv, value: string) {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        ...this.localEnv,
        [key]: value,
      })
    )
    location.reload()
  }

  /**
   * Метод установки переменных окружения
   * @param env
   */
  public setLocalEnv(env: Partial<IEnv>) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(env))
    location.reload()
  }
}

export const initialization = async (): Promise<IEnv> => {
  const response = await axios.get<IEnv>('/env.json')
  const envManager = new Environments(response.data)

  window.ENV_MANAGER = envManager

  return envManager.env
}
