import React from 'react'
import ReactDOM from 'react-dom'

import { initialization } from '@common/utils/env'

console.log(`%cVersion: ${process.env.REVISION}`, 'background-color: black; color: white')

const applicationInitialization = async () => {
  window.ENV = await initialization()

  const { App } = await import(
    /* webpackChunkName: "app" */
    './app'
  )

  ReactDOM.render(<App />, document.getElementById('root'))
}

void applicationInitialization()
